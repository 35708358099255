import * as React from 'react'
import { ICoordinates } from '../../Looking4Beer/Shared/Types/Location'

interface CoordinatesStateInterface {
  coordinates: ICoordinates,
  setCoordinates: any
}

const initialCoordinates = {
  lat: 27.786635847182573,
  lng: -82.57575168422674,
}

const CoordinatesContext = React.createContext<CoordinatesStateInterface>({
  coordinates: initialCoordinates,
  setCoordinates: () => {
  },
})

export const CoordinatesProvider = (props: any) => {
  const [coordinates, setCoordinates] = React.useState<ICoordinates>(initialCoordinates)

  React.useEffect(() => {
    navigator.geolocation.watchPosition(({ coords: { latitude, longitude } }) => {
      setCoordinates({ lat: latitude, lng: longitude })
    }, console.log, {
      maximumAge: 300000,
      timeout: 2000,
    })
  }, [])

  const values = React.useMemo<CoordinatesStateInterface>(() => ({
    coordinates, setCoordinates,
  }), [coordinates])

  return (
    <CoordinatesContext.Provider value={values}>
      {props.children}
    </CoordinatesContext.Provider>
  )

}

export const useCoordinates = () => {
  const context = React.useContext(CoordinatesContext)

  if (context === undefined) {
    throw new Error('`useCoordinates` hook must be used within a `CoordinatesProvider` component')
  }

  return context
}
