import * as React from 'react'
import { useReducer } from 'react'
import { IBrewery } from '../../Looking4Beer/Breweries/Types/IBrewery'


interface SelectedBreweriesInterface {
  selectedBreweries: string[];
  addBrewery: (brewery: IBrewery) => void;
  removeBrewery: (brewery: IBrewery) => void;
  clearSelected: () => void;
  isSelected: (brewery: IBrewery) => boolean;
}

const SelectedBreweriesContext = React.createContext<SelectedBreweriesInterface>({
  selectedBreweries: [],
  addBrewery: (b: IBrewery) => {
  },
  clearSelected: () => {
  },
  isSelected: (b: IBrewery) => (false),
  removeBrewery: (b: IBrewery) => {
  },
})

function selectedBreweriesReducer(state: any, action: any) {
  switch (action.type) {
    case 'ADD':
      return [
        ...state,
        action.id,
      ]
    case 'REMOVE':
      return state.filter((breweryId: string) => breweryId != action.id)
    case'CLEAR':
      return []
    default:
      throw new Error('Action not specified')
  }
}

export const SelectedBreweriesProvider = (props: any) => {
  const [selectedBreweries, dispatch] = useReducer(selectedBreweriesReducer, [])

  const addBrewery = (brewery: IBrewery) => {
    dispatch({ type: 'CLEAR' })
    dispatch({ type: 'ADD', id: brewery.id })
  }

  const removeBrewery = (brewery: IBrewery) => dispatch({ type: 'REMOVE', id: brewery.id })

  const clearSelected = () => dispatch({ type: 'CLEAR' })

  const isSelected = (brewery: IBrewery) => selectedBreweries.filter((selectedBreweryId: string) => (selectedBreweryId === brewery.id)).length > 0

  const values = React.useMemo(() => ({
    selectedBreweries,
    addBrewery,
    removeBrewery,
    clearSelected,
    isSelected,
  }), [selectedBreweries])

  return (
    <SelectedBreweriesContext.Provider value={values}>
      {props.children}
    </SelectedBreweriesContext.Provider>
  )
}

export const useSelectedBreweries = () => {
  const context = React.useContext(SelectedBreweriesContext)

  if (context === undefined) {
    throw new Error('`useSelectedBreweries` hook must be used within a `SelectedBreweriesProvider` component')
  }
  return context
}
