import React, { useEffect, useMemo, useReducer } from 'react'
import GoogleMapReact, { meters2ScreenPixels } from 'google-map-react'
import GeoElement from '../../Types/GeoElement'
import ICoordinates from '../../Types/ICoordinates'

enum MapBoundsAction {
  CHANGE_CENTER,
  CHANGE_MAP
}

interface BoundsState {
  zoom: number,
  mapCenter: ICoordinates,
  width: number
}

type ChangeCenterAction = { type: MapBoundsAction.CHANGE_CENTER } & Pick<BoundsState, 'mapCenter'>
type ChangeMapAction = { type: MapBoundsAction.CHANGE_MAP } & Pick<BoundsState, 'mapCenter' | 'zoom' | 'width'>


type ChangeBoundsAction = ChangeCenterAction | ChangeMapAction;


const DEFAULT_ZOOM = 11

function createMapOptions(maps: any) {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
      style: maps.ZoomControlStyle.SMALL,
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_LEFT,
    },
    mapTypeControl: true,
    fullscreenControl: false,
  }
}

function mapBoundsReducer(state: BoundsState, action: ChangeBoundsAction): BoundsState {
  switch (action.type) {
    case MapBoundsAction.CHANGE_CENTER:
      return {
        ...state,
        mapCenter: action.mapCenter,
      }
    case MapBoundsAction.CHANGE_MAP:
      return {
        ...state,
        mapCenter: action.mapCenter,
        zoom: action.zoom,
        width: action.width,
      }
    default:
      return state
  }
}

function getRadius(center: ICoordinates, zoom: number, width: number) {
  // Use 1km as base of unit
  // meters2ScreenPixels is returning how many pixels are need to show 1km in the current zoom level
  const conversionFactor = meters2ScreenPixels(1000, {
    lat: center.lat,
    lng: center.lng,
  }, zoom)
  return width / conversionFactor.w
}

interface MapProps {
  elements: GeoElement[];
  center: ICoordinates;
  Marker: React.FC<{ element: any } & ICoordinates>;
  onChange?: (center: ICoordinates, radius: number) => void
}

export default function Map({ elements, center, onChange, Marker }: MapProps) {

  const [state, dispatch] = useReducer<typeof mapBoundsReducer, any>(mapBoundsReducer, {
    mapCenter: {
      lat: 0,
      lon: 0,
    },
    zoom: DEFAULT_ZOOM,
    width: 0,
  }, (init: BoundsState) => (init))

  useEffect(() => {
    dispatch({ type: MapBoundsAction.CHANGE_CENTER, mapCenter: center })
  }, [center])


  const radius = useMemo(() => getRadius(state.mapCenter, state.zoom, state.width), [state])

  useEffect(() => {
    onChange && onChange(state.mapCenter, radius)
  }, [state])

  //TODO: Move to utility
  // const identifyCall = (method) => (...args) => console.log(`ID: ${method}`, args)
  return (<>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.GATSBY_REACT_APP_GOOGLE_API_KEY || '' }}
        center={
          {
            ...state.mapCenter,
          }
        }
        defaultZoom={DEFAULT_ZOOM}
        options={createMapOptions}
        onChange={({ zoom, size: { width }, center, ...p }) => { //resize window, zoom change
          if (zoom !== state.zoom ||
            width !== state.width ||
            center.lat != state.mapCenter.lat ||
            center.lng != state.mapCenter.lng
          ) {
            dispatch({
              type: MapBoundsAction.CHANGE_MAP, mapCenter: center,
              zoom,
              width,
            })
          }
        }}
        resetBoundsOnResize={true}
      >
        {elements.map((element) =>
            element.coordinates && <Marker
              key={`map_marker_${element.coordinates.lat}-${element.coordinates.lng}`}
              element={element}
              lat={element.coordinates.lat}
              lng={element.coordinates.lng}
            />,
        )}
      </GoogleMapReact>
    </>
  )
}
