import * as React from 'react'
import { createRef, RefObject, useEffect } from 'react'
import { Card, CardHeader, createStyles, makeStyles, Popper, Theme } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { LocationOn } from '@material-ui/icons'
import { IBrewery } from '../../models/Brewery'
import { useSelectedBreweries } from '../../contexts/SelectedBreweries/SelectedBreweriesContext'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      maxWidth: 345,
    },
    // media: {
    //     height: 0,
    //     paddingTop: '56.25%', // 16:9
    // },
    // expand: {
    //     transform: 'rotate(0deg)',
    //     marginLeft: 'auto',
    //     transition: theme.transitions.create('transform', {
    //         duration: theme.transitions.duration.shortest,
    //     }),
    // },
    // expandOpen: {
    //     transform: 'rotate(180deg)',
    // },
    avatar: {
      backgroundColor: red[500],
    },
    marker: {
      color: red[500],
    },
  }),
)

interface BreweryMapMarkerPropsInterface {
  element: IBrewery,
  lat: number,
  lng: number
}

export default function BreweryMapMarker({ element: brewery, ...restProps }: BreweryMapMarkerPropsInterface) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const { selectedBreweries, addBrewery, isSelected } = useSelectedBreweries()

  const ref = createRef() as RefObject<HTMLButtonElement>
  useEffect(() => {
    if (isSelected(brewery)) {
      ref.current && ref.current.click()
    }
  }, [selectedBreweries])

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget)
    addBrewery(brewery)
  }

  const open = Boolean(anchorEl) && isSelected(brewery)
  const id = open ? 'simple-popover' : undefined
  return <div>
    <LocationOn
      className={classes.marker}
      aria-describedby={id}
      ref={ref}
      onClick={handleClick}/>
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}>
      <Card className={classes.card}>
        <CardHeader
          title={brewery.name}
        />
      </Card>
    </Popper>
  </div>
}
