import styled from 'styled-components'

export const Column = styled.div`
  flex: ${props => props.size || 1};
  height: 100%;
`

export const Sidebar = styled(Column)`
  height: 100%;
  max-height: 95vh;
  overflow: scroll;
`
