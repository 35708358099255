import React from 'react'
import { fetchNearbyBreweries } from '../../state/breweriesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Column, Sidebar } from '../../styled/Column'
import BrewerySummary from '../../Looking4Beer/Breweries/UseCases/BrewerySummary'
import BreweryMap from '../../Looking4Beer/Map/UseCases/Map'
import { SelectedBreweriesProvider } from '../../contexts/SelectedBreweries/SelectedBreweriesContext'
import BasicPage from '../../styled/BasicPage'
import { useCoordinates } from '../../contexts/Coordinates/CoordinatesContext'
import { ICoordinates } from '../../Looking4Beer/Shared/Types/Location'
import BreweryMapMarker from '../../components/BreweryMapMarker/BreweryMapMarker'
import { IState } from '../../state/createStore'
import GeoElement from '../../Looking4Beer/Map/Types/GeoElement'
import { IBrewery } from '../../Looking4Beer/Breweries/Types/IBrewery'

function Map() {
  const { coordinates } = useCoordinates()

  const breweries = useSelector<IState, Array<GeoElement & IBrewery>>(state => state.breweries.data)
  const dispatch = useDispatch()

  const onChange = (coordinates: ICoordinates, radius: number) => {
    dispatch(fetchNearbyBreweries({ coordinates, km: radius }))
  }

  return (
    <BasicPage>
      <SelectedBreweriesProvider>
        <Sidebar>
          {breweries.length > 0 ?
            breweries.map((brewery, idx) => (
              <BrewerySummary key={`breweryListing_${idx}`} brewery={brewery}/>
            ))
            : <h2>No Breweries found in the area</h2>}
        </Sidebar>
        <Column size={3}>
          <BreweryMap elements={breweries} onChange={onChange} Marker={BreweryMapMarker}
                      center={coordinates}/>
        </Column>
      </SelectedBreweriesProvider>
    </BasicPage>
  )
}

export default Map
