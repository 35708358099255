import styled from 'styled-components'

export const Column = styled.div`
  flex: ${({ size }: { size: number }) => size || 1}
  height: 100%
`

export const Sidebar = styled(Column)`
  height: 100%;
  max-height: 95vh;
  overflow: scroll;
`

export const Layout = styled.div`
  display: flex;
  flex-direction: ${({ direction }: {direction: 'vertical' | 'horizontal'}) => direction === 'vertical' ? 'column' : 'row'}
`

export const Header = styled.header`
  height: 5em;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #7A3C00;
  color: #5CBC00;
`

export const Logo = styled.h1`
  font-family: 'Lobster';
  flex-grow: 1;
`

export const UserMenu = styled.div`
  margin-right: 1em;
`
