import * as React from 'react'
import { IBrewerySimpleSummary } from '../../Types/IBrewery'
import Card from '@material-ui/core/Card'
import { CardContent } from '@material-ui/core'
import { Link } from 'gatsby'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

interface IBrewerySummaryProps {
  brewery: IBrewerySimpleSummary
}

const useStyles = makeStyles({
  summaryCard: {
    marginTop: 24,
  },
  title: {
    fontSize: 24,
    fontWeight: 900,
  },
})

export default function BrewerySummary({ brewery }: IBrewerySummaryProps) {
  const classes = useStyles()

  return (
    <Card className={classes.summaryCard}>
      <CardContent>
        <Link to={`/brewery/${brewery.id}`}>
          <Typography className={classes.title} color="textSecondary" gutterBottom>{brewery.name}</Typography>
        </Link>
        {brewery.phoneNumber && brewery.phoneNumber.replace(/^(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
      </CardContent>
    </Card>
  )
}
