import React from 'react'
import { Box } from '@material-ui/core'
import { Layout } from './BasicElements'
import theme from './Theme'
import { GlobalStyle } from './GlobalStyle'

import { ThemeProvider } from '@material-ui/styles'
import Navigation from './Navigation'
import Helmet from 'react-helmet'

export default function BasicPage({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lobster"/>
      </Helmet>
      <GlobalStyle/>
      <Box style={{ height: '100vh', flexDirection: 'column', display: 'flex' }}>
        <Navigation/>
        <Layout direction={'horizontal'} style={{ flexGrow: 1 }}>
          {children}
        </Layout>
      </Box>
    </ThemeProvider>
  )
}
